import React, { Component } from 'react';
import { Button, DatePicker, Select, Spin } from 'antd';
import { asyncConnect } from 'react-async-client';
import { withState } from 'recompose';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ClockCircleOutlined, EditOutlined } from '@ant-design/icons';

import { getPhotoReports, getCurrentCheckin } from 'actions/asyncActions';
import { fullAutoOptions } from 'constants/asyncClient';
import { PlusOutlined } from '@ant-design/icons';
import { openSaleEditModal, openImportModal } from 'actions/modalActions';
import withRole from '../../common/withRole';
import { PHOTO_REPORTS_TYPES, PHOTO_REPORTS_MAP } from 'constants/photoReports';
import DateField from 'components/common/cards/Date';
import ShopName from 'components/common/cards/shop/ShopName';

const TaskWrapper = styled.div`
    padding: 16px 8px;
    background: #324153;
    border-radius: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
`;

const TaskTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    margin-bottom: 5px;
    p {
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        margin-bottom: 0;
    }
`;

const TaskDate = styled.div`
    font-size: 13px;
    color: ${({ highlight }) => highlight ? '#FFFF00' : '#8F8F8F'};
`;

const TaskDraft = styled.div`
    right: 30px;
    position: absolute;
    font-size: 13px;
    color: #FFFF00;
`;

@withRole
@withState('date', 'setDate', {
    minDate: moment().subtract(7, 'days').startOf('day'),
    maxDate: moment().endOf('day')
})
@withState('filter', 'setFilter', {})
@asyncConnect({
    getCurrentCheckin: getCurrentCheckin
        .withOptions(fullAutoOptions),
    getPhotoReports: getPhotoReports
        .withPayload(({ filter, date }) => ({
            q: {
                ...filter,
                ...date
            },
            limit: 200,
            sort_by: 'date',
            sort_order: 'desc',
        }))
        .withOptions(fullAutoOptions)
}, null, { openSaleEditModal, openImportModal })
export default class PhotoReportsMobile extends Component {
    renderReports = () => {
        const { getPhotoReports: { data }, getCurrentCheckin: { data: checkin } } = this.props;

        return <div>
            {(data.items || []).map(report => (
                <Link id={report.id} to={`/photo/edit/${report.id}`}>
                    <TaskWrapper>
                        <div>
                            <TaskTitle className='task-table-text'>
                                {PHOTO_REPORTS_MAP[report.type] || report.type}
                            </TaskTitle>
                            <TaskDate highlight><ClockCircleOutlined /> <DateField hideIcon date={report.date} /></TaskDate>
                            {report._embedded.network.name} <ShopName shop={report._embedded.shop} />
                        </div>
                        { (checkin && checkin.status !== 'finished' && report.date === checkin.date) && <TaskDraft><EditOutlined /></TaskDraft>}
                    </TaskWrapper>
                </Link>
            ))}
        </div>;
    }

    toggleFilter = () => this.setState(prev => ({ filterOpened: !prev.filterOpened }));

    onChangeDate = range => {
        const [fromDate, toDate] = range;

        this.props.setDate({
            minDate: fromDate.startOf('day'),
            maxDate: toDate.endOf('day')
        });
    }

    render() {
        const { getPhotoReports: { meta }, getCurrentCheckin: { meta: metaCheckin }, date, filter, setFilter } = this.props;

        return <div className='layout-wrap'>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 15, marginTop: 5 }}>
                <DatePicker.RangePicker
                    dropdownClassName='range-sales'
                    style={{ width: '100%' }}
                    value={[date.minDate, date.maxDate]}
                    placeholder={['с', 'по']}
                    onChange={this.onChangeDate}
                    allowClear={false} />
                <Select
                    value={filter.type}
                    onChange={type => setFilter({ type })}
                    placeholder='Отчет'
                    allowClear={true}
                    style={{ marginLeft: 6, width: '40%' }}
                >
                    {PHOTO_REPORTS_TYPES.map(option => (
                        <Select.Option key={option.id} value={JSON.stringify(option.id)}>
                            { option.name }
                        </Select.Option>
                    ))}
                </Select>
                <Link to='/photo/add'>
                    <Button className='fab-btn' type='primary' shape="circle" icon={<PlusOutlined />} size="large" />
                </Link>
            </div>
            {meta.pending || metaCheckin.pending ?
                <div style={{ textAlign: 'center' }}><Spin /></div> :
                this.renderReports()
            }
        </div>
    }
}
